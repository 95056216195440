import PettyCash from "./views/PettyCash";
import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";
import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
import PettyCashList from "./components/PettyCashList";
import PettyCashForm from "./components/PettyCashForm";
import PettycashDocument from "./components/PettycashDocument";
export default [
  {
    path: "/creditcard",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: { view: PettyCash },
        children: [
          {
            path: "/",
            redirect: "/creditcard/list",
          },
          {
            path: "list",
            name: "creditcard",
            component: PettyCashList,
            meta: { middleware: [Auth] },
          },

          {
            path: "form",
            name: "creditcardForm",
            component: PettyCashForm,
            meta: { middleware: [Auth] },
          },
          {
            path: "creditcard-document/:docNo",
            name: "creditcardDocument",
            component: PettycashDocument,
            meta: { middleware: [Auth] },
          },
        ],
      },
    ],
  },
];
